.mat-mdc-tab-header {
  width: 100%;
  border-bottom: 1px solid var(--Black-96);
  background: var(--Black-5);
  
}

.mat-mdc-tab-labels {
  width: 60%;
  padding: 0px 40px; 
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab .mdc-tab__content .mdc-tab__text-label {
  font-family: DM Sans;
  color: var(--Black-48);
  font-family: DM Sans;
  letter-spacing: 0;
  font-size: 14px;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: var(--Blue-72);
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--Blue-72);
  font-family: DM Sans;
  letter-spacing: 0;
  font-size: 14px;
}

/* mat-paginator.mat-mdc-paginator {
  border: 1px solid var(--Black-88);
  border-top: none;
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
} 


table {
  width: 100%;
}

table tr:first-child th:not(first-child) {
  border-top: 1px solid var(--Black-88);
}

table tr td:first-child {
  border-left: 1px solid var(--Black-88);
}

table tr td:last-child {
  border-right: 1px solid var(--Black-88);
}

table tr:last-child td {
  border-bottom: 1px solid var(--Black-88) !important;
}

table tr:first-child th:first-child {
  border-top-left-radius:10px;
  border-top: 1px solid var(--Black-88);
  border-left: 1px solid var(--Black-88);
}

table tr:first-child th:last-child {
  border-top-right-radius: 10px;
  border-top: 1px solid var(--Black-88);
  border-right: 1px solid var(--Black-88);
}

table tr:last-child td:first-child {
  border-bottom-left-radius:10px !important;
  border-bottom: 1px solid var(--Black-88) !important;
  border-left: 1px solid var(--Black-88) !important;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 10px !important;
  border-bottom: 1px solid var(--Black-88) !important;
  border-right: 1px solid var(--Black-88) !important;
}


tr.example-detail-row {
  height: 0;
}

tr.example-element-row:not(.example-expanded-row):nth-child(even) {
  background: var(--Black-5);
}

tr.example-element-row:not(.example-expanded-row):hover {
  background: var(--Black-5);
}

tr.example-element-row:not(.example-expanded-row):active {
  background: var(--Black-5);
}

.example-element-row td {
  border-bottom-width: 0;
}

.mat-mdc-table .mat-mdc-header-cell {
  padding: 20px;
}
.example-element-row td.mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 20px;
}

.example-element-row .mat-mdc-row {
  padding: 20px;
}

tr.mat-mdc-header-row {
  background-color: var(--Black-5);
} */

/* .mat-mdc-form-field-infix {
  height: 40px !important;
  min-height: 20px !important;
}

.mat-mdc-form-field {
  width: 100%;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 0px;
  width: 100%;
}
.mat-mdc-select-trigger {
  height: 100%;
}
.mdc-text-field--outlined .mdc-notched-outline {
  height: 40px;
}

.mat-mdc-form-field-icon-prefix>.mat-icon, .mat-mdc-form-field-icon-suffix>.mat-icon {
  padding: 0px 8px !important;
  color: var(--Black-48);
}

#childTable {
  padding-bottom: 20px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input {
  height: 100%;
} */