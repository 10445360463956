@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,700");

.Header-1 { /*Title*/
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 120%;
}

.Header-2 {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 128%;
    letter-spacing: 0.1px;
}
.Header-3 {
    font-family: 'DM Sans', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 128%;
}
.Header-4 {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.2px;
}
.Header-5 {
    font-family: 'DM Sans', 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 125%;
    letter-spacing: 0.1px;
}
.Body1 {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 128%;
    letter-spacing: 0.1px;
}
.Body2 {
    font-family: 'DM Sans', 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
}
.Button {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.2px;
}
.Overline {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 128%;
    letter-spacing: 0.1px;
}

.small-14bold {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.2px;
}

.small-12reg {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.2px;
}

.small-13reg {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0.2px;
}

.small-14reg {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
}

.small-12med {
    font-style: normal;
    font-weight: 600; /*initial 500 less diff with normal = 400 */
    font-size: 12px;
    letter-spacing: 0.2px;
}

.small-12bld {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.2px;
}

.bold{
    font-weight:bold;
}

.small-9med {
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    letter-spacing: normal;
}

.k-grid .k-table-alt-row {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.k-grid .k-table-row {
    border-color: white !important;
}