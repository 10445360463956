.k-component {
  border-radius: 20px;
  background: #fff;
  border-color: var(--Black-88);
  font-family: inherit;
} 

.k-input-md .k-input-button,
.k-input-md .k-spinner-increase,
.k-input-md .k-spinner-decrease,
.k-picker-md .k-input-button,
.k-picker-md .k-spinner-increase,
.k-picker-md .k-spinner-decrease {
  background-color: #fff;
  border: none;
}

.new-style[_ngcontent-lao-c616] input[_ngcontent-lao-c616] {
  color: black !important;
}

.k-component {
  opacity: 1 !important;
}


::ng-deep .k-component .k-button {
  background-color: #fff;
  border: none;
}

.k-diagnosis {
  background: #fff;
  border: none;
  height: 40px;
  font-family: inherit;
}


/* .k-disabled, */
.k-widget[disabled],
input.k-input:disabled,
input.k-input[disabled],
textarea.k-textarea:disabled,
textarea.k-textarea[disabled] {
  background-color: var(--Disable-bg) !important;
}


.k-combobox {
  display: flex;
}

.k-mb-3 {
  margin-bottom: 0.75rem !important;
}

.k-component::placeholder {
  font-size: 14px;
  color: #757575;
}

.k-textarea::placeholder {
  font-size: 14px;
  color: #757575;
}

.k-textarea {
  font-size: 14px;
}


.k-component span {
  font-size: 14px;
}

.k-component:hover {
  background: transparent;
}

.k-component:focus {
  background: transparent;
}

.k-animation-container * {
  font-family: inherit;
}

.k-input:focus-visible .k-input:focus {
  border: none;
}
.k-grid .k-grid-header .k-table-th {
  background-color: var(--Black-5);
}
.k-popup {
  box-shadow: none !important;
}

div.k-grid-header, div.k-grid-footer {
  padding: 0px !important;
}