
kendo-grid {
    border: 1px solid var(--Black-88);
    border-radius: 8px;
}

.k-grid,
.k-grid th {
    font-family: 'DM Sans', 'sans-serif';
    font-size: 14px;
    font-weight: 500;
}

    .k-grid,
    .k-grid td {
        font-family: 'DM Sans', 'sans-serif';
        font-size: 14px;
        font-weight: 400;
    }

.k-grid-pager {
    border-top: 1px solid var(--Black-88);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.k-grid-header {
    border-bottom: 1px solid var(--Black-88);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.k-grid-header {
    padding: 0 !important;
}

.k-grid-content {
    overflow-y: visible;
}

.k-grid-header-wrap {
    border-right: none;
}

kendo-grid-list {
    /*border-radius: 0 0 8px 8px;*/
}

::ng-deep kendo-grid .k-plus:before {
    content: "\e015";
}

::ng-deep kendo-grid .k-minus:before {
    content: "\e013";
}

.k-grid-header {
    font-weight: bold;
}

.k-window-titlebar {
    color: #fff;
    background: #ccc;
    line-height: 1.5em;
}

    .k-window-titlebar .k-i-x {
        color: #666;
    }

    .k-window-titlebar button.k-button-icon {
        display: none !important;
    }

.k-window-title {
    font-size: 1.1em;
    text-transform: uppercase;
}

td a.nav-link,
td .nav-link {
    color: var(--Blue-72);
    text-decoration: none;
    padding: 0 !important;
    cursor: pointer;
}

.k-grid-header .k-header:last-child,
.k-grid td:last-child {
    text-overflow: clip;
}

.k-grid-header .k-header:first-child,
.k-grid td:first-child {
    text-overflow: clip;
}

.k-grid th,
.k-grid td {
    padding: .75rem 15px !important;
    border: none !important;
}

.k-grid tr {
    border-bottom: 1px solid var(--Black-88);
}

    .k-grid tr:last-child {
        border-bottom: unset;
    }

.k-grid table {
    border-collapse: collapse;
}

/*.k-grid .k-detail-row td.k-hierarchy-cell {
    display: none;
}
.k-grid .k-detail-row td.k-detail-celll {
    column-span: ;
}*/

@media only screen and (max-device-width: 422px) {
    ::ng-deep .k-grid.k-grid-no-scrollbar .k-grid-content {
        overflow-y: auto !important;
        overflow-x: auto !important;
        max-height: 40vh !important;
    }
}


@media screen and (min-width: 597px) {
    #small-grid {
        display: none;
    }
}

#small-grid,
#big-grid {
    height: calc(100vh - 200px);
}

@media only screen and (min-width: 992px) {
    #big-grid {
        height: calc(100vh - 140px);
    }
}

@media screen and (max-width: 596.98px) {
    #big-grid {
        display: none;
    }
}

#small-grid kendo-pager {
    align-items: start;
    grid-template-columns: repeat(3, auto);
    display: grid;
    flex-wrap: wrap;
}

#small-grid kendo-pager-input > *{
    margin: 0;
}

#small-grid kendo-pager-prev-buttons,
#small-grid kendo-pager-next-buttons {
    display: flex;
}

#small-grid kendo-pager-next-buttons {
    justify-content: end;
}

#small-grid kendo-pager-info {
    grid-column: 1 / span 3;
}

.k-grid-header .k-header>.k-link>.k-icon.k-i-sort-desc-sm, .k-grid-header .k-header>.k-link>.k-icon.k-i-sort-asc-sm {
    margin-left: 1px!important;
}

.k-grid-header .k-header>.k-link {
    display: flex;
    flex-flow: row nowrap;
}

.k-grid-header .k-header>.k-link :first-child  {
    text-overflow: ellipsis;
    overflow: hidden;
}