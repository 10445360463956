.icon-24 {
    background-color: var(--Black-72);
    width: 24px;
    height: 24px;
    display: inline-block;
    margin: auto;
}

    .icon-24:hover {
        background-color: var(--Blue-72);
    }
    .icon-24.disabled:hover {
        background-color: var(--Black-72);
        cursor: not-allowed;
    }
 
    .icon-14 {
        background-color: var(--Black-72);
        width: 14px;
        height: 14px;
        display: inline-block;
        margin: auto;
    }

.filter-icon {
    mask: url(/assets/img/svg/filter_list.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/filter_list.svg) no-repeat center;
}

.add-circle-icon {
    mask: url(/assets/img/svg/plus-o.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/plus-o.svg) no-repeat center;
}

.client-icon {
    mask: url(/assets/img/svg/client-01.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/client-01.svg) no-repeat center;
}

.staffs-icon {
    mask: url(/assets/img/svg/staff-01.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/staff-01.svg) no-repeat center;
}

.user-icon {
    mask: url(/assets/img/svg/user.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/user.svg) no-repeat center;
}

.location-icon {
    mask: url(/assets/img/svg/location.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/location.svg) no-repeat center;
}

.company-icon {
    mask: url(/assets/img/svg/settings-01.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/settings-01.svg) no-repeat center;
}

.resource-icon {
    mask: url(/assets/img/svg/training-01.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/training-01.svg) no-repeat center;
}

.dashboard-icon {
    mask: url(/assets/img/svg/dashboard-01.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/dashboard-01.svg) no-repeat center;
}

.report-icon {
    mask: url(/assets/img/svg/templates-01.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/templates-01.svg) no-repeat center;
}

.scheduling-icon {
    mask: url(/assets/img/svg/calendar-01.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/calendar-01.svg) no-repeat center;
}

.tasks-icon {
    mask: url(/assets/img/svg/notification-01.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/notification-01.svg) no-repeat center;
}

.billing-icon {
    mask: url(/assets/img/svg/billing-01.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/billing-01.svg) no-repeat center;
}

.bars-icon-r {
    mask: url(/assets/img/svg/arrow-right-r.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/arrow-right-r.svg) no-repeat center;
}

.bars-icon-l {
    mask: url(/assets/img/svg/arrow-left-r.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/arrow-left-r.svg) no-repeat center;
}

.my-account-icon {
    mask: url(/assets/img/svg/profile.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/profile.svg) no-repeat center;
}

.reset-password-icon {
    mask: url(/assets/img/svg/password.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/password.svg) no-repeat center;
}

.logout-icon {
    mask: url(/assets/img/svg/log-out.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/log-out.svg) no-repeat center;
}

.down-icon {
    mask: url(/assets/img/svg/chevron-down.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/chevron-down.svg) no-repeat center;
}

.up-icon {
    mask: url(/assets/img/svg/chevron-up.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/chevron-up.svg) no-repeat center;
}

.right-icon {
    mask: url(/assets/img/svg/chevron-right.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/chevron-right.svg) no-repeat center;
}

.chevron-left-icon {
    mask: url(/assets/img/svg/chevron-left.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/chevron-left.svg) no-repeat center;
}

.chevron-right-icon {
    mask: url(/assets/img/svg/chevron-right.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/chevron-right.svg) no-repeat center;
}

.rebill-icon {
    mask: url(/assets/img/svg/rotation-left.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/rotation-left.svg) no-repeat center;
}

.bill-next-icon {
    mask: url(/assets/img/svg/bill-next.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/bill-next.svg) no-repeat center;
}

.block-icon {
    mask: url(/assets/img/svg/block.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/block.svg) no-repeat center;
}

.notes-icon {
    mask: url(/assets/img/svg/notes.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/notes.svg) no-repeat center;
}

.notes-r-icon {
    mask: url(/assets/img/svg/notes-r.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/notes-r.svg) no-repeat center;
}

.notes-with-pen-icon {
    mask: url(/assets/img/svg/note-with-pen.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/note-with-pen.svg) no-repeat center;
}

.void-icon {
    mask: url(/assets/img/svg/delete_sweep.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/delete_sweep.svg) no-repeat center;
}

.flag-icon {
    mask: url(/assets/img/svg/flag.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/flag.svg) no-repeat center;
}

.printer-icon {
    mask: url(/assets/img/svg/printer.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/printer.svg) no-repeat center;
}

.chat-icon {
    mask: url(/assets/img/svg/chat-icon.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/chat-icon.svg) no-repeat center;
}

.note-icon {
    mask: url(/assets/img/svg/notes.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/notes.svg) no-repeat center;
}

.checkmark-icon {
    mask: url(/assets/img/svg/checkmark.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/checkmark.svg) no-repeat center;
}

.edit-icon {
    mask: url(/assets/img/svg/edit.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/edit.svg) no-repeat center;
}

.trash-icon {
    mask: url(/assets/img/svg/trash.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/trash.svg) no-repeat center;
}

.save-name-icon {
    mask: url(/assets/img/svg/check-o-large.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/check-o-large.svg) no-repeat center;
}

.denied-icon {
    mask: url(/assets/img/svg/x-o.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/x-o.svg) no-repeat center;
}

.processed-icon {
    mask: url(/assets/img/svg/check-o-status.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/check-o-status.svg) no-repeat center;
}

.passed-icon {
    mask: url(/assets/img/svg/check-o.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/check-o.svg) no-repeat center;
}

.download-icon {
    mask: url(/assets/img/svg/download.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/download.svg) no-repeat center;
}

.close-icon {
    mask: url(/assets/img/svg/x.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/x.svg) no-repeat center;
}

.dollar-icon {
    mask: url(/assets/img/svg/dollar.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/dollar.svg) no-repeat center;
}

.phone-icon {
    mask: url(/assets/img/svg/phone.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/phone.svg) no-repeat center;
}

.mail-icon {
    mask: url(/assets/img/svg/mail.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/mail.svg) no-repeat center;
}

.software-upload-icon {
    mask: url(/assets/img/svg/software-upload.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/software-upload.svg) no-repeat center;
}

.file-document-icon {
    mask: url(/assets/img/svg/file-document.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/file-document.svg) no-repeat center;
}

.close-o-icon {
    mask: url(/assets/img/svg/close-o.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/close-o.svg) no-repeat center;
}

.push-pin-icon {
    mask: url(/assets/img/svg/push-pin.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/push-pin.svg) no-repeat center;
}
.desc-var1-icon {
    mask: url(/assets/img/svg/desc-var1.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/desc-var1.svg) no-repeat center;
}

.asc-var1-icon {
    mask: url(/assets/img/svg/asc-var1.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/asc-var1.svg) no-repeat center;
}

.desc-var2-icon {
    mask: url(/assets/img/svg/desc-var2.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/desc-var2.svg) no-repeat center;
}

.asc-var2-icon {
    mask: url(/assets/img/svg/asc-var2.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/asc-var2.svg) no-repeat center;
}

.Buiding-icon {
    mask: url(/assets/img/svg/Buiding.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/Buiding.svg) no-repeat center;
}

.pay-icon {
    mask: url(/assets/img/svg/pay.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/pay.svg) no-repeat center;
}

.delete-icon {
    mask: url(/assets/img/svg/delete.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/delete.svg) no-repeat center;
}

.edit-small-icon {
    mask: url(/assets/img/svg/edit-small.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/edit-small.svg) no-repeat center;
}

.plus-icon {
    mask: url(/assets/img/svg/plus.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/plus.svg) no-repeat center;
}

.reconcile-icon {
    mask: url(/assets/img/svg/reconcile.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/reconcile.svg) no-repeat center;
}

.info-icon {
    mask: url(/assets/img/svg/info.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/info.svg) no-repeat center;
}

.info-red-icon {
    mask: url(/assets/img/svg/info-red.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/info-red.svg) no-repeat center;
}

.info-yellow-icon {
    mask: url(/assets/img/svg/info-yellow.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/info-yellow.svg) no-repeat center;
}

.refresh-icon {
    mask: url(/assets/img/svg/refresh.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/refresh.svg) no-repeat center;
}

.search-icon {
    mask: url(/assets/img/svg/search.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/search.svg) no-repeat center;
}

.copy-icon {
    mask: url(/assets/img/svg/copy.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/copy.svg) no-repeat center;
}

.software-download-icon {
    mask: url(/assets/img/svg/software-download.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/software-download.svg) no-repeat center;
}

.arrow-top-right-r-icon {
    mask: url(/assets/img/svg/arrow-top-right-r.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/arrow-top-right-r.svg) no-repeat center;
}

.arrow-up {
    mask: url(/assets/img/svg/arrow-up.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/arrow-up.svg) no-repeat center;
}

.arrow-down {
    mask: url(/assets/img/svg/arrow-down.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/arrow-down.svg) no-repeat center;
}

.arrow-top-right-r-icon {
    mask: url(/assets/img/svg/arrow-top-right-r.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/arrow-top-right-r.svg) no-repeat center;
}

.approve-icon {
    mask: url(/assets/img/svg/approve.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/approve.svg) no-repeat center;
}

.unavailable-icon {
    mask: url(/assets/img/svg/unavailable.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/unavailable.svg) no-repeat center;
}

.pen-icon {
    mask: url(/assets/img/svg/pen.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/pen.svg) no-repeat center;
}

.write-off-icon {
    mask: url(/assets/img/svg/write-off.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/write-off.svg) no-repeat center;
}

.flag-alt-icon {
    mask: url(/assets/img/svg/flag-alt.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/flag-alt.svg) no-repeat center;
}

.flag-alt2-icon {
    mask: url(/assets/img/svg/flag-alt2.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/flag-alt2.svg) no-repeat center;
}

.customize-icon {
    mask: url(/assets/img/svg/customize.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/customize.svg) no-repeat center;
}

.error-outline-y-icon {
    mask: url(/assets/img/svg/error-outline-y.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/error-outline-y.svg) no-repeat center;
}

.error-outline-r-icon {
    mask: url(/assets/img/svg/error-outline-r.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/error-outline-r.svg) no-repeat center;
}

.unapprove-icon {
    mask: url(/assets/img/svg/unapprove.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/unapprove.svg) no-repeat center;
}

.arrow-right-icon {
    mask: url(/assets/img/svg/arrow-right.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/arrow-right.svg) no-repeat center;
}

.claim-rebill-icon {
    mask: url(/assets/img/svg/rebill.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/rebill.svg) no-repeat center;
}

.unflag-icon {
    mask: url(/assets/img/svg/unflag.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/unflag.svg) no-repeat center;
}

.funnel-icon {
    mask: url(/assets/img/svg/filter.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/filter.svg) no-repeat center;
}

.sort-az-icon {
    mask: url(/assets/img/svg/sort-az.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/sort-az.svg) no-repeat center;
}

.sort-za-icon {
    mask: url(/assets/img/svg/sort-za.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/sort-za.svg) no-repeat center;
}

.newest-oldest-icon {
    mask: url(/assets/img/svg/newest-oldest.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/newest-oldest.svg) no-repeat center;
}

.oldest-newest-icon {
    mask: url(/assets/img/svg/oldest-newest.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/oldest-newest.svg) no-repeat center;
}

.refresh-right-icon {
    mask: url(/assets/img/svg/refresh-right.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/refresh-right.svg) no-repeat center;
}

.layout-grid-small-icon {
    mask: url(/assets/img/svg/layout-grid-small.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/layout-grid-small.svg) no-repeat center;
}

.exclamation-point-icon {
    mask: url(/assets/img/svg/exclamation-point.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/exclamation-point.svg) no-repeat center;
}

.help-icon {
    mask: url(/assets/img/svg/help.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/help.svg) no-repeat center;
}

.lock-icon {
    mask: url(/assets/img/svg/lock.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/lock.svg) no-repeat center;
}

.multi-auth-icon {
    mask: url(/assets/img/svg/multi-auth.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/multi-auth.svg) no-repeat center;
}

.pw-lock-icon {
    mask: url(/assets/img/svg/pw-lock.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/pw-lock.svg) no-repeat center;
}

.password-shown-icon {
    mask: url(/assets/img/svg/password-shown-icon.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/password-shown.svg) no-repeat center;
}

.password-hidden-icon {
    mask: url(/assets/img/svg/password-hidden-icon.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/password-hidden.svg) no-repeat center;
}

.ss-mfa-mail-icon {
    mask: url(/assets/img/svg/ss-mfa-mail.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/ss-mfa-mail.svg) no-repeat center;
}

.ss-mfa-sms-icon {
    mask: url(/assets/img/svg/ss-mfa-sms.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/ss-mfa-sms.svg) no-repeat center;
}

.ss-mfa-voice-icon {
    mask: url(/assets/img/svg/ss-mfa-voice.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/ss-mfa-voice.svg) no-repeat center;
}

.eye-icon {
    mask: url(/assets/img/svg/eye.svg) no-repeat center;
    -webkit-mask: url(/assets/img/svg/eye.svg) no-repeat center;
}